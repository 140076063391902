<template>
    <div class="root">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{$route.name}}</span>
            </div>

            <!-- 搜索表单 -->
            <el-form :inline="true" :model="searchData">
                <el-form-item style="float:right">
                    <el-button type="success" size="small" plain @click="popup()">新增照片</el-button>
                </el-form-item>
            </el-form>

            <!-- 表格 -->
            <el-table :data="tableData" stripe style="width: 100%" v-loading="tableloading">
                <!-- 表格数据列 -->
                <!-- <el-table-column prop="id" label="ID" width="100"></el-table-column> -->
                <el-table-column label="图片">
                    <template slot-scope="item">
                        <el-image class="photo-min" :src="env.baseUrl + item.row.min" :preview-src-list="[env.baseUrl + item.row.url]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="文字"></el-table-column>
                <el-table-column prop="date" label="拍摄日期"></el-table-column>
                <el-table-column prop="create_time" label="上传时间"></el-table-column>

                <el-table-column label="操作" width="160">
                    <template slot-scope="item">
                        <el-button type="primary" size="mini" @click="popup(item.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="deleteItem(item.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 表格分页 -->
            <div class="pager">
                <el-pagination background layout="prev, pager, next" :total="pageTotal" :current-page="searchData.page" @current-change="changePage"></el-pagination>
            </div>

            <!-- 编辑/新增弹窗 -->
            <el-dialog :title="(editData.id == undefined ? '新增' : '编辑') + '照片'" :visible.sync="editShow" :close-on-click-modal="false" @closed="closeEdit" :custom-class="'popup-box'">

                <el-form :model="editData" ref="editForm" label-width="80px" label-position="right" v-loading="editLoading">
                    <el-form-item label="文字">
                        <el-input v-model="editData.title" auto-complete="off" size="medium"></el-input>
                    </el-form-item>
                    <el-form-item label="拍摄日期">
                        <el-date-picker v-model="editData.date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" size="medium"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="图片上传" v-if="!editData.id">
                        <el-upload :action="env.baseUrl + '/admin/upload_photo'" :file-list="fileList" list-type="picture" :multiple="true" :before-upload="beforeUpload" :on-success="uploadSuccess" :on-error="uploadError" :on-remove="uploadRemove">
                            <el-button size="mini" type="primary" :loading="uploadLoading">上传图片</el-button>
                        </el-upload>
                    </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button size="small" type="primary" :loading="editLoading" @click="submit">保存</el-button>
                </div>
            </el-dialog>

        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                editData: {
                    title: '',
                    date: ''
                },
                fileList: [],
                editLoading: false,
                editShow: false,
                uploadLoading: false,
                // 搜索数据
                searchData: {
                    page: 1
                },
                // 表格数据
                pageTotal: 0,
                tableloading: true,
                tableData: [],
            }
        },
        created() {
            this.loadTable()
        },
        methods: {
            // 表格请求
            loadTable() {
                let that = this
                this.ajax({
                    url: '/admin/photo',
                    data: that.searchData,
                    beforeSend() {
                        that.tableloading = true
                    },
                    complete() {
                        that.tableloading = false
                    },
                    success(res) {
                        that.pageTotal = res.data.count
                        that.tableData = res.data.list
                    },
                    error(res) {
                        that.$message.error(res.message);
                    },
                })
            },
            // 搜索
            search() {
                this.searchData.page = 1
                this.loadTable()
            },
            // 翻页
            changePage(page) {
                this.searchData.page = page
                this.loadTable()
            },
            // 打开弹窗
            popup(param) {
                let that = this
                that.editData = param ? param : {
                    title: '',
                    date: ''
                }
                this.editShow = true
            },
            // 关闭弹窗
            closeEdit() {
                this.fileList = []
                this.$refs['editForm'].resetFields()
            },
            // 点击提交
            submit() {
                let that = this
                let originData = JSON.parse(JSON.stringify(that.editData))
                let ajaxData = {
                    title: originData.title,
                    date: originData.date ? originData.date.slice(0, 10) : ''
                }
                // 新增则附加图片
                if (originData.id === undefined) {
                    if (that.fileList.length === 0) {
                        that.$message({
                            message: '未上传照片',
                            type: 'warning'
                        })
                        return false
                    }
                    ajaxData.photos = []
                    for (let i = 0; i < that.fileList.length; i++) {
                        ajaxData.photos.push({
                            url: that.fileList[i].response.data.url,
                            min: that.fileList[i].response.data.min
                        })
                    }
                } else {
                    ajaxData.id = originData.id
                }
                that.ajax({
                    url: '/admin/photo',
                    type: ajaxData.id ? 'put' : 'post',
                    data: ajaxData,
                    beforeSend() {
                        that.editLoading = true
                    },
                    complete() {
                        that.editLoading = false
                    },
                    success() {
                        // 编辑本地刷新
                        if (originData.id === undefined) that.searchData.page = 1
                        that.loadTable()
                        that.editShow = false
                        that.$message({
                            message: '成功',
                            type: 'success'
                        });
                    },
                    error(res) {
                        that.$message.error(res.message);
                    },
                })

            },
            // 删除
            deleteItem(index) {
                let that = this
                let data = that.tableData[index]
                that.$confirm('确认删除？')
                    .then(() => {
                        that.ajax({
                            url: '/admin/photo',
                            type: 'delete',
                            data: {
                                id: data.id
                            },
                            beforeSend() {
                                that.tableloading = true
                            },
                            complete() {
                                that.tableloading = false
                            },
                            success() {
                                that.loadTable()
                                that.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                            },
                            error(res) {
                                that.$message.error(res.message);
                            },
                        })
                    })
                    .catch(() => {})
            },
            // 图片上传
            beforeUpload() {
                this.uploadLoading = true
            },
            uploadSuccess(res, file, fileList) {
                this.uploadLoading = false
                if (res.status === 100000) {
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    })
                    this.fileList = fileList
                } else {
                    this.$message.error(res.message || '服务器错误')
                }
            },
            uploadError() {
                this.uploadLoading = false
                this.$message.error('服务器错误')
            },
            uploadRemove(file, fileList) {
                this.fileList = fileList
            }
        }
    }
</script>

<style lang="less">
    .el-image-viewer__close {
        color: #fff;
    }
</style>

<style lang="less" scoped>
    .pager {
        padding-top: 20px;
        text-align: right;
    }

    .photo-min {
        width: 100px;
    }
</style>