import { render, staticRenderFns } from "./photo.vue?vue&type=template&id=2378ae36&scoped=true&"
import script from "./photo.vue?vue&type=script&lang=js&"
export * from "./photo.vue?vue&type=script&lang=js&"
import style0 from "./photo.vue?vue&type=style&index=0&id=2378ae36&prod&lang=less&"
import style1 from "./photo.vue?vue&type=style&index=1&id=2378ae36&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2378ae36",
  null
  
)

export default component.exports